var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{style:({
    'background-image' : 'url(https://picsum.photos/1024/768?random)',
    'background-size':'cover'
  }),attrs:{"fluid":"","fill-height":""}},[_c('v-row',{attrs:{"fill-height":"","justify":"center","justify-sm":'center',"justify-md":'center'}},[_c('v-col',{style:({
      'background-color': 'white',
      'border-radius': '5px',
      opacity:'.85'
    }),attrs:{"cols":"12","sm":"6","md":"5","lg":"4","align":"center","align-self":"center"}},[_c('img',{staticClass:"mb-5",style:({
          height : _vm.$vuetify.breakpoint.mdAndUp?'3.4rem':'3rem'
        }),attrs:{"src":require("@/assets/logo.png"),"alt":"logo"}}),_c('v-layout',{attrs:{"row":"","fill-height":"","wrap":"","justify-center":"","align-center":""}},[_c('ValidationObserver',{ref:"form_pass_forgot",scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('v-col',{attrs:{"cols":"12","xs":"12"}},[_c('ValidationProvider',{attrs:{"vid":"pw","name":"password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{class:{'secondary--text':true, 'pr-3': _vm.$vuetify.breakpoint.smAndUp},attrs:{"name":"password","label":_vm.$tc('user.password'),"error-messages":errors[0],"type":_vm.showPass2 ? 'text' : 'password',"append-icon":_vm.showPass ? 'fa-eye' : 'fa-eye-slash'},on:{"click:append":function($event){_vm.showPass = !_vm.showPass}},model:{value:(_vm.new_password),callback:function ($$v) {_vm.new_password=$$v},expression:"new_password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","xs":"12"}},[_c('ValidationProvider',{attrs:{"vid":"password","name":"password_confirmation","rules":"required|confirmed:pw"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"password_confirmation","label":_vm.$tc('user.password_repeat'),"error-messages":errors[0],"type":_vm.showPass2 ? 'text' : 'password',"append-icon":_vm.showPass2 ? 'fa-eye' : 'fa-eye-slash'},on:{"click:append":function($event){_vm.showPass2 = !_vm.showPass2}},model:{value:(_vm.new_password_repeat),callback:function ($$v) {_vm.new_password_repeat=$$v},expression:"new_password_repeat"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('span',{class:{'flex':_vm.$vuetify.breakpoint.mdAndUp, 'sm6':_vm.$vuetify.breakpoint.mdAndUp}},[_c('v-btn',{attrs:{"color":"success","loading":_vm.load},on:{"click":function($event){return _vm.setpassword()}}},[_vm._v(" "+_vm._s(_vm.$t('actions.send'))+" ")])],1)])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }