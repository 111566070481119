export default{
  data(){
    return{
      new_password: '',
      new_password_repeat: '',
      showPass: false,
      showPass2: false,
      load:false
    }
  },
  methods:{
    setpassword(){
        this.load = true
        this.$refs.form_pass_forgot.validate().then(result => {
          if (result) {
            let data = {
              "new_password": this.new_password,
              "uid": this.$route.params.uid,
              "token": this.$route.params.hash
            }
            this.axios.post(this.$store.getters.forgotSetPassword, data)
              .then(() => {
                this.$awn.success(this.$t('user.password_reset.succeeded'))
                this.load = false
                this.$router.push("/login")
              })
              .catch(() => {
                this.load = false
                this.$awn.alert(this.$t('user.password_reset.invalid_token'))
              })
          }
        })
    }
  }
}